import React from 'react';

function Footer() {
  return (
    <footer className='footer'>
      {/* Links to FAQ, Examples, Pricing, Contact, Feedback, Twitter, Privacy */}
    </footer>
  );
}

export default Footer;
